import * as React from "react"
import './podcast.scss'

const Podcast = () => {
    return (
	
        <div className="container podcast">
            <div>
                <h3>Listen to the Chicken Soup for the Soul Podcast</h3>
                <p className="consent">To enable the podcast player and listen here, your browser must accept marketing cookies and allow JavaScript.</p>
            </div>
        </div>
    )
}

export default Podcast