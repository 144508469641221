import React, { useEffect } from "react"
import {graphql} from "gatsby";
import Affiliate from "../../components/global/affiliate";
import ImageLink from "../../components/global/imageLink"
import InnerLayout from "../../components/global/innerLayout"
import parse from "html-react-parser"
import Podcast from "../../components/global/podcast"
import defaultFrontCover from "../../images/coming-soon-front-cover.png"
import './freeStory.scss'

export const query = graphql`
  query ($id: [Craft_QueryArgument], $publishedDateFilter: [Craft_QueryArgument]) {
      craft {
        houseAds: globalSet(handle: "freeStoriesHouseAds") {
          ... on Craft_freeStoriesHouseAds_GlobalSet {
            houseAdTop {
              ... on Craft_houseAdTop_ad_BlockType {
                key: id
                image {
                  url(transform: "storiesLeaderboardAd728x90Transform")
                  ... on Craft_websiteHouseAds_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
            houseAdLeft {
              ... on Craft_houseAdLeft_ad_BlockType {
                key: id
                image {
                  url(transform: "storiesSkyscraperAd160x600Transform")
                  ... on Craft_websiteHouseAds_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
            houseAdBottom {
              ... on Craft_houseAdBottom_ad_BlockType {
                key: id
                image {
                  url(transform: "storiesLeaderboardAd728x90Transform")
                  ... on Craft_websiteHouseAds_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
          }
        }
        storyDetails: entry(
          section: "freeStoryPage"
          id: $id
          includeInFreeStories: true
        ) {
          ... on Craft_freeStoryPage_default_Entry {
          seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            title
            storyContributor
            description: contentExtendedButtonsImgOptionsFreeStory
            newsletterTopic {
              key: id
              title
              slug
            }
            book {
              ... on Craft_bookPage_default_Entry {
                title
                slug
                bookTitlePrepend
                affiliateEnabledAmazon
                affiliateEnabledAmazonCa
                affiliateEnabledBookshopOrg
                affiliateEnabledIndiebound
                affiliateEnabledIndigo
                affiliateLinkIndigo
                affiliateEnabledBarnesNoble
                affiliateLinkBarnesNoble
                isbn10
                isbn13
                bookFrontCover {
                  key: id
                  url(transform: "bookCoverLarge")
                  ... on Craft_websiteBookPage_Asset {
                    altText
                  }
                }
              }
            }
            prev(section: "freeStoryPage", includeInFreeStories: true, newsletterPublishDate: $publishedDateFilter, orderBy: "newsletterPublishDate desc") {
              key: id
              slug
              title
            }
            next(section: "freeStoryPage", includeInFreeStories: true, newsletterPublishDate: $publishedDateFilter, orderBy: "newsletterPublishDate desc") {
              key: id
              slug
              title
            }
          }
        }
      }
    }
`

const getStoryLink = (link, prepend) => {
    if (link) {
        return (
            <p>{prepend} <a href={"/story/" + link.key + "/" + link.slug}>{link.title}</a></p>
        )
    } else {
        return null
    }
}

const getBookFrontCover = (node) => {
    if (node.bookFrontCover.length > 0) {
        return node.bookFrontCover[0].url
    } else {
        return defaultFrontCover
    }
}

const getBookFrontCoverAltText = (node) => {
    if (node.bookFrontCover.length > 0) {
        return node.bookFrontCover[0].altText
    } else {
        return null
    }
}

const getHouseAd = (node) => {
    if (node.length > 0) {
        return (
            <ImageLink src={node[0].image[0].url} alt={node[0].image[0].altText} to={node[0].imageLink[0].linkUrl} openInNewTab={node[0].imageLink[0].openInNewTab}></ImageLink>
        )
    } else {
        return null
    }
}

const getBreadcrumbs = (node) => {
    if (node.length > 0) {
        return node.map(newsletter => (
            <li><a href={`/stories/${newsletter.slug}`}>{newsletter.title}</a></li>
        ))
    } else {
        return null
    }
}

const FreeStory = ({ data, pageContext }) => {

    useEffect(() => {
        // Disable right click
        document.querySelector('.story-content').addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
    })

    return (
        <InnerLayout seomatic={data.craft.storyDetails.seomatic} entryId={pageContext.id} forceNewsletterSignupEnabled={true}>
            <div className="midd border-top">
                <div className="story-page">
                    <div className="container-small">
                        <div className="banner-top">
                            {getHouseAd(data.craft.houseAds.houseAdTop)}
                        </div>
                        <div className="content-colum-row story-row">
                            <div className="sidebar">
                                <div className="breadcrumb">
                                    <ul>
                                        <li><a href="/stories">Free Stories</a></li>
                                        {getBreadcrumbs(data.craft.storyDetails.newsletterTopic)}
                                    </ul>
                                </div>
                                <div className="side-bar-book-cover">
                                    <ImageLink to={"/book/" + data.craft.storyDetails.book[0].slug} src={getBookFrontCover(data.craft.storyDetails.book[0])} alt={getBookFrontCoverAltText(data.craft.storyDetails.book[0])}/>
                                    <a href={"/book/" + data.craft.storyDetails.book[0].slug} className="btn btn-primary">Learn More</a>
                                </div>
                                <div className="side-banner">
                                    {getHouseAd(data.craft.houseAds.houseAdLeft)}
                                </div>
                            </div>
                            <div className="content-right">
                                <div className="story-details">
                                    <div className="breadcrumb">
                                        <ul>
                                            <li><a href="/stories">Free Stories</a></li>
                                            {getBreadcrumbs(data.craft.storyDetails.newsletterTopic)}
                                        </ul>
                                    </div>
                                    <h4>{data.craft.storyDetails.title}</h4>
                                    <p>From <a href={"/book/" + data.craft.storyDetails.book[0].slug}>{data.craft.storyDetails.book[0].bookTitlePrepend} {data.craft.storyDetails.book[0].title}</a> </p>
                                    <p>{data.craft.storyDetails.storyContributor}</p>

                                    <Affiliate book={data.craft.storyDetails.book[0]}/>
                                </div>

                                <div className="story-content">
                                    {
                                        // Check if the story has a description
                                        (data.craft.storyDetails.description)
                                            ? parse(data.craft.storyDetails.description)
                                            : null
                                    }
                                </div>

                                <div className="copy-notice">
                                    <p>Reprinted by permission of Chicken Soup for the Soul, LLC {new Date().getFullYear()}. In order to protect the rights of the copyright holder, no portion of this publication may be reproduced without prior written consent. All rights reserved.</p>
                                </div>

                                <div className="content-pagination">
                                    {getStoryLink(data.craft.storyDetails.next, "Previous:")}
                                    {getStoryLink(data.craft.storyDetails.prev, "Next:")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-banner">
                        <div className="container">
                            {getHouseAd(data.craft.houseAds.houseAdBottom)}
                        </div>
                    </div>
                </div>
            </div>
            <Podcast />
        </InnerLayout>
    )
}

export default FreeStory