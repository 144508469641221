import * as React from 'react'
import amazon from "../../images/affiliate/amazon.png"
import amazoncanada from "../../images/affiliate/amazoncanada.png"
import barnesandnoble from "../../images/affiliate/barnesandnoble.png"
import indiebound from "../../images/affiliate/indiebound.png"
import indigo from "../../images/affiliate/indigocanada.png"
import bookshop from "../../images/affiliate/bookshoporg.png"
import "./affiliate.scss"

const Affiliate = ({book}) => {
    const getAmazon = () => {
        if (book.affiliateEnabledAmazon && book.isbn10) {
            return <li><a href={`https://www.amazon.com/gp/product/${book.isbn10}/?ie=UTF8&tag=chickensoupweb-20&linkCode=xm2&camp=1789&creativeASIN=${book.isbn10}`} target="_blank" rel="noreferrer"><img src={amazon} alt="Amazon" /></a></li>
        } else {
            return null
        }
    }

    const getAmazonCanada = () => {
        if (book.affiliateEnabledAmazonCa && book.isbn10) {
            return <li><a href={`https://www.amazon.ca/exec/obidos/ASIN/${book.isbn10}`} target="_blank" rel="noreferrer"><img src={amazoncanada} alt="Amazon Canada" /></a></li>
        } else {
            return null
        }
    }

    const getBarnesAndNoble = () => {
        if (book.affiliateEnabledBarnesNoble && book.affiliateLinkBarnesNoble) {
            return <li><a href={`https://www.anrdoezrs.net/click-7224934-12366831?url=${encodeURIComponent(book.affiliateLinkBarnesNoble)}&source_code=`} target="_blank" rel="noreferrer"><img src={barnesandnoble} alt="Barnes & Noble" /></a></li>
        } else {
            return null
        }
    }

    const getIndiebound = () => {
        if (book.affiliateEnabledIndiebound && book.isbn13) {
            return <li><a href={`https://www.indiebound.com/book/${book.isbn13.replace('-', '')}?aff=chickensoup`} target="_blank" rel="noreferrer"><img src={indiebound} alt="Indiebound" /></a></li>
        } else {
            return null
        }
    }

    // Hard coded to false for now until CSS is ready to go live with Indigo and has the link pattern
    const getIndigo = () => {
        if (false && book.affiliateEnabledIndigo && book.affiliateLinkIndigo) {
            return <li><a href="#" target="_blank" rel="noreferrer"><img src={indigo} alt="Indigo" /></a></li>
        } else {
            return null
        }
    }

    const getBookshop = () => {
        if (book.affiliateEnabledBookshopOrg && book.isbn13) {
            return <li><a href={`https://bookshop.org/a/21751/${book.isbn13.replace('-', '')}`} target="_blank" rel="noreferrer"><img src={bookshop} alt="Bookshop" /></a></li>
        } else {
            return null
        }
    }

    const getAffiliateEnabled = () => {
        return (book.affiliateEnabledAmazon && book.isbn10)||
            (book.affiliateEnabledAmazonCa && book.isbn10) ||
            book.affiliateEnabledBookshopOrg && book.isbn13||
            (book.affiliateEnabledIndiebound && book.isbn13) ||
            (book.affiliateEnabledIndigo && book.affiliateLinkIndigo) ||
            (book.affiliateEnabledBarnesNoble && book.affiliateLinkBarnesNoble)
    }

    if (getAffiliateEnabled()) {
        return (
            <div className="affiliate">
                <h5>Buy From:</h5>
                <ul>
                    {getAmazon()}
                    {getBarnesAndNoble()}
                    {getIndiebound()}
                    {getIndigo()}
                    {getAmazonCanada()}
                    {getBookshop()}
                </ul>
                <p className="disclaimer">We are participants of Amazon Associates, Barnes & Noble, Bookshop.org, and Indiebound affiliate programs and will earn commissions for qualifying purchases made through links on this page.</p>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}

export default Affiliate